import React from "react";
import { graphql } from "gatsby";

import Article from "../components/commons/Article";
import GridRow from "../components/commons/GridRow";
import Heading from "../components/commons/Heading";
import ArticleThumbnail from "../components/commons/ArticleThumbnail";
import SubPage from "../components/organisms/SubPage";
import SeeMoreGrid from "../components/organisms/SeeMoreGrid";

export default function ArticlePage({
  data: { sanityArticle, moreArticles, sanityPage },
}) {
  const data = sanityArticle;
  return (
    <SubPage
      title={data.seoTitle || data.title}
      description={data.seoDescription}
      pathname={data.slug.current}
      banner={data.heroImage}
      page={sanityPage}
    >
      <Article
        title={data.title}
        body={data._rawBody}
        author={data.author}
        date={{
          value: data.publishedAtRaw,
          label: data.publishedAt,
        }}
      />
      <GridRow
        as="section"
        sx={{
          my: [5, 7],
        }}
      >
        <GridRow.Col>
          <Heading as="h3">More Articles</Heading>
        </GridRow.Col>
        <GridRow.Col>
          <SeeMoreGrid>
            {moreArticles.map((node) => (
              <ArticleThumbnail
                key={node.title}
                to={`/${sanityPage.slug.current}/${node.slug.current}`}
                title={node.title}
                subtitle={`${node.publishedAt} by ${node.author.name}`}
                media={node.heroImage}
                avatar={node.author.image}
              />
            ))}
          </SeeMoreGrid>
        </GridRow.Col>
      </GridRow>
    </SubPage>
  );
}

export const pageQuery = graphql`
  query ArticlePage($sanityId: String!) {
    sanityArticle(_id: { eq: $sanityId }) {
      publishedAt(formatString: "D MMMM YYYY")
      publishedAtRaw: publishedAt
      title
      seoTitle
      seoDescription
      slug {
        current
      }
      heroImage {
        alt
        asset {
          fluid(maxWidth: 1400) {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
      author {
        ...Author
      }
    }
    moreArticles(limit: 3, currentId: $sanityId) {
      ...ArticleThumbnail
    }
    sanityPage(_id: { eq: "blog" }) {
      slug {
        current
      }
      blocks {
        ...Blocks
      }
    }
  }
`;
