import React from "react";

import GridRow from "../../commons/GridRow";

export default function SeeMoreGrid({ children, ...otherProps }) {
  return (
    <GridRow
      variant="threeColumns"
      sx={{
        gridRowGap: 5,
        "& > *:nth-of-type(3)": {
          display: ["block", "none", "block"],
        },
      }}
    >
      {children}
    </GridRow>
  );
}
